import {
  Col,
  Container,
  Nav,
  OverlayTrigger,
  Row,
  Tab,
  Table,
  Tooltip,
} from "react-bootstrap";

function Home() {
  const display = true;
  return (
    <>
      <Container fluid className="bg-light">
        <Row>
          <Col>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col className="bg-white shadow-lg ms-2 me-1 my-2 rounded">
                  <Nav variant="underline">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Today</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">7 days</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">30 days</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">60 days</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="py-2">
                    <Tab.Pane eventKey="first">
                      <Row>
                        <Col>
                          <h6 className="fw-bold">Sales</h6>
                          <h3 className="text-success mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                        <Col>
                          <h6 className="fw-bold">Purchase</h6>
                          <h3 className="text-danger mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Row>
                        <Col>
                          <h6 className="fw-bold">Sales</h6>
                          <h3 className="text-success mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                        <Col>
                          <h6 className="fw-bold">Purchase</h6>
                          <h3 className="text-danger mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Row>
                        <Col>
                          <h6 className="fw-bold">Sales</h6>
                          <h3 className="text-success mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                        <Col>
                          <h6 className="fw-bold">Purchase</h6>
                          <h3 className="text-danger mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <Row>
                        <Col>
                          <h6 className="fw-bold">Sales</h6>
                          <h3 className="text-success mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                        <Col>
                          <h6 className="fw-bold">Purchase</h6>
                          <h3 className="text-danger mb-0">
                            <i className="fa fa-rupee fa-xs"></i>&nbsp;0
                          </h3>
                          <small>0 orders</small>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
          <Col>
            <Tab.Container id="middle-tabs-example" defaultActiveKey="first">
              <Row>
                <Col className="bg-white shadow-lg mx-1 my-2 rounded">
                  <Nav variant="underline">
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Items which are Expiring in 2 months.
                        </Tooltip>
                      }
                    >
                      <Nav.Item className="pe-2">
                        <Nav.Link eventKey="first">
                          Expiring Items&nbsp;
                          <i className="fas fa-info-circle fa-xs"></i>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          Items which are already Expired.
                        </Tooltip>
                      }
                    >
                      <Nav.Item className="ps-2">
                        <Nav.Link eventKey="second">
                          Expired Items&nbsp;
                          <i className="fas fa-info-circle fa-xs"></i>
                        </Nav.Link>
                      </Nav.Item>
                    </OverlayTrigger>
                  </Nav>
                  <Tab.Content className="py-2">
                    <Tab.Pane eventKey="first">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-secondary">Item Name</th>
                            <th className="text-secondary">Expiring Qty.</th>
                            <th className="text-secondary">Expiry</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!display ? (
                            <tr>
                              <td>Dolo 650</td>
                              <td>1</td>
                              <td>50</td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                <p className="text-center py-5 text-secondary">
                                  <i className="fas fa-info-circle fa-xs"></i>
                                  &nbsp;No Record Found
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <p className="viewall">View all</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Table responsive>
                        <thead>
                          <tr>
                            <th className="text-secondary">Item Name</th>
                            <th className="text-secondary">Expiring Qty.</th>
                            <th className="text-secondary">Expiry</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!display ? (
                            <tr>
                              <td>Dolo 650</td>
                              <td>1</td>
                              <td>50</td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={4}>
                                <p className="text-center py-5 text-secondary">
                                  <i className="fas fa-info-circle fa-xs"></i>
                                  &nbsp;No Record Found
                                </p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                      <p className="viewall">View all</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
          <Col>
            <Row>
              <Col className="bg-white shadow-lg ms-1 me-2 my-2 rounded">
                <p className="fw-bold ">
                  Sales Margin&nbsp;
                  <i className="fas fa-info-circle fa-xs"></i>
                </p>
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-secondary">Item Name</th>
                      <th className="text-secondary">Pack</th>
                      <th className="text-secondary">Stock</th>
                      <th className="text-secondary">Margin</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!display ? (
                      <tr>
                        <td>Dolo 650</td>
                        <td>1</td>
                        <td>50</td>
                        <td>200</td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <p className="text-center py-5 text-secondary">
                            <i className="fas fa-info-circle fa-xs"></i>&nbsp;No
                            Record Found
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
