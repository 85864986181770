import logo from "./logo.svg";
import "./App.css";

import Sales from "./pages/sales/Sales";
import Purchase from "./pages/purchase/Purchase";
import { Link, Route, Routes } from "react-router-dom";
import NewPurchase from "./pages/purchase/NewPurchase";
import Login from "./authentication/Login";
import MainLayout from "./pages/MainLayout";
import Home from "./pages/Home";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/" element={<MainLayout />}>
          <Route path="Index" element={<Home />} />
          <Route path="Sales" element={<Sales />} />
          <Route path="Purchases" element={<Purchase />} />
          <Route path="New-purchase" element={<NewPurchase />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
