import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Purchase() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigation = useNavigate();
  return (
    <>
      <Container fluid>
        <Row className="py-2">
          <Col className="d-flex justify-content-between">
            <div className="">
              <Button variant="outline-success" size="sm" className="me-2">
                Purchase
              </Button>
              <Button
                variant="success"
                size="sm"
                className="ms-2"
                onClick={handleShow}
              >
                New Purchase
              </Button>
            </div>
            <Button variant="danger" size="sm">
              Purchase Returns
            </Button>
          </Col>
          <Modal
            show={show}
            onHide={handleClose}
            animation={false}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <div>
                <h4 className="mb-0">Add Purchase</h4>
                <small>
                  Choose Product addition method from below to add products in
                  your inventory
                </small>
              </div>
            </Modal.Header>
            <Modal.Body>
              <Button variant="outline-primary" className="w-100 my-1">
                Bulk Uploads
              </Button>
              <p className="text-center pb-0 mb-0 text-secondary">
                <small>OR</small>
              </p>
              <Button
                variant="outline-success"
                className="w-100 my-1"
                onClick={() => {
                  navigation("/New-purchase");
                }}
              >
                Add Manually
              </Button>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    </>
  );
}

export default Purchase;
