import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Sales() {
  const [startDate, setStartDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState("");
  const [billItem, setBillItem] = useState({
    medicine: "",
    batch: "",
    expdate: "",
    mrp: "",
    qty: "",
    discount: "",
    dprice: "",
    gst: "",
    stkleft: "",
    amount: "",
  });
  const todayDate = new Date();
  const [show, setShow] = useState(false);
  const [printBillItems, setPrintBillItems] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const data = [
    {
      id: 1,
      medicine: "dolo 650",
      batch: "6511",
      expdate: "2025-06",
      mrp: "1.9",
      discount: "0",
      dprice: "0",
      gst: "",
      stkleft: "125",
    },
    {
      id: 2,
      medicine: "Monteck Lc",
      batch: "7525",
      expdate: "2025-08",
      mrp: "19.87",
      discount: "0",
      dprice: "0",
      gst: "",
      stkleft: "125",
    },
    {
      id: 3,
      medicine: "Monticine Syrup",
      batch: "12545",
      expdate: "2026-01",
      mrp: "85.23",
      discount: "0",
      dprice: "0",
      gst: "",
      stkleft: "125",
    },
    {
      id: 4,
      medicine: "Coldact 250mg",
      batch: "3245",
      expdate: "2027-11",
      mrp: "2.31",
      discount: "0",
      dprice: "0",
      gst: "",
      stkleft: "125",
    },
  ];
  const onSearchMedicine = (item) => {
    setSearchValue(item);
  };
  const onBillItemHandler = (item) => {
    const medicine = data.find((m) => m.id === item);
    setBillItem({
      medicine: medicine.medicine,
      batch: medicine.batch,
      expdate: medicine.expdate,
      mrp: medicine.mrp,
      qty: "",
      discount: medicine.discount,
      dprice: medicine.dprice,
      gst: medicine.gst,
      stkleft: medicine.stkleft,
      amount: "",
    });
  };
  const addItemtoBillHandler = () => {
    if (billItem.qty === "") {
      alert("Please enter quantity!");
    } else {
      const itemAmount = parseFloat(billItem.mrp) * parseFloat(billItem.qty);
      setPrintBillItems((current) => [
        ...printBillItems,
        { ...billItem, amount: itemAmount.toFixed(2) },
      ]);
      console.log(printBillItems);
      setSearchValue("");
      setBillItem({
        medicine: "",
        batch: "",
        expdate: "",
        mrp: "",
        qty: "",
        discount: "",
        dprice: "",
        gst: "",
        stkleft: "",
        amount: "",
      });
    }
  };
  return (
    <>
      <Container fluid className="bg-sales">
        <Row className="my-0 py-2">
          <Col className="my-0 py-0 d-flex justify-between ">
            <h5 className="pb-1 mb-0">
              <span className="text-secondary">Sales</span>{" "}
              <i className="fas fa-angle-right fa-sm text-secondary"></i>
              &nbsp;New
            </h5>
            <div className="d-flex items-center">
              <Button variant="outline-primary" size="sm" className="px-3 me-2">
                <i className="fas fa-bell"></i>&nbsp;&nbsp;Set Reminder
              </Button>
              <p className="pb-0 mb-0 pe-1">
                <InputGroup className="">
                  <InputGroup.Text
                    id="basic-addon1"
                    className="px-1 border border-primary"
                  >
                    <i className="fas fa-money-bill-wave fa-lg text-primary"></i>
                  </InputGroup.Text>
                  <Form.Select className="me-2 sales-select ps-1 py-1 border border-primary">
                    <option value="Cash">Cash</option>
                    <option value="Credit">Credit</option>
                    <option value="UPI">UPI</option>
                    <option value="Cheque">Cheque</option>
                    <option value="CC/DC">CC/DC</option>
                    <option value="RTGS/NEFT">RTGS/NEFT</option>
                  </Form.Select>
                </InputGroup>
              </p>
              <p className="pb-0 mb-0 ps-1">
                <Button variant="primary" size="sm" className="px-4">
                  Save&nbsp;&nbsp;&nbsp;<i className="fas fa-save"></i>
                </Button>
                <Button variant="primary" size="sm" className="ms-2 px-3">
                  <i className="fab fa-whatsapp fa-xl"></i>
                </Button>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col md={10} className="px-0">
            <Row className="py-2 mx-0">
              <Col
                md={2}
                className="d-flex items-center bg-menu py-2 rounded-md "
              >
                <p className="mb-0 pb-0 pe-3 fs-4">
                  <i className="fas fa-calendar-alt bg-primary p-2 rounded-2xl text-white"></i>
                </p>
                <Form.Group
                  className="mb-0"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="mb-0 pb-0 text-white">
                    Bill Date
                  </Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    maxDate={new Date()}
                    className="bg-none"
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className="d-flex items-center bg-custde shadow-sm py-2 rounded-md border"
              >
                <p className="mb-0 pb-0 pe-3 fs-4">
                  <i className="fas fa-user-alt bg-primary p-2 rounded-2xl text-white"></i>
                </p>
                <Form.Group
                  className="mb-0"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="mb-0 pb-0 d-flex justify-between items-center">
                    <span>Customer Details</span>{" "}
                    <span className="medi-points text-success fw-bold">
                      Medi Coins:{" "}
                      <span className="text-danger fs-6 fw-bold ps-1">0</span>
                    </span>
                  </Form.Label>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    animation={false}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Join Medicoll Family!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-2">
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Customer phone number"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Full name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Customer full name"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Customer email id"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Set Default Discount(%)</Form.Label>
                            <Form.Control type="text" placeholder="0" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Customer address line1"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>&nbsp;</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Customer address line2"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter city"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Pincode</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter pincode"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Modal.Body>
                  </Modal>
                  <div className="d-flex justify-end items-center">
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      className="new-cust bg-custde border-0 border-bottom ps-0 py-0"
                    />
                    <InputGroup className="cust-det ps-2">
                      <InputGroup.Text id="basic-addon1 border-0 border-bottom phn-cust pb-0 mb-0">
                        <i className="fas fa-mobile-alt"></i>
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Enter phone"
                        className="new-cust bg-custde border-0 border-bottom ps-0 py-0"
                      />
                    </InputGroup>
                    <Button
                      variant="primary"
                      size="sm"
                      className="px-5 ms-2"
                      onClick={handleShow}
                    >
                      Add
                    </Button>
                  </div>
                </Form.Group>
              </Col>
              <Col
                md={4}
                className="d-flex items-center bg-custde shadow-sm py-2 rounded-md border"
              >
                <p className="mb-0 pb-0 pe-3 fs-4">
                  <i className="fas fa-stethoscope bg-primary p-2 rounded-2xl text-white"></i>
                </p>
                <Form.Group
                  className="mb-0"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="mb-0 pb-0 d-flex justify-between items-center">
                    <span>Doctor</span>
                  </Form.Label>
                  <div className="d-flex justify-end items-center">
                    <InputGroup className="cust-det">
                      <Form.Control
                        type="text"
                        placeholder="Enter Doctor name"
                        className="doct-cust bg-custde border-0 border-bottom ps-0 py-0"
                      />
                      <InputGroup.Text id="basic-addon1 border-0 border-bottom phn-cust pb-0 mb-0">
                        <i className="fas fa-search"></i>
                      </InputGroup.Text>
                    </InputGroup>

                    {/* <Button variant="primary" size="sm" className="px-3">
                  Add
                </Button> */}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="pt-4 mx-0">
              <Col className="px-0">
                <Row className="mb-2 pb-1 border-0 border-bottom">
                  <Col md={3} className="fw-bold text-secondary">
                    Medicine Name
                  </Col>
                  <Col className="fw-bold text-secondary">Batch</Col>
                  <Col className="fw-bold text-secondary">Exp. Date</Col>
                  <Col className="fw-bold text-secondary">MRP</Col>
                  <Col className="fw-bold text-secondary">QTY</Col>
                  <Col className="fw-bold text-secondary">Disc%</Col>
                  <Col className="fw-bold text-secondary">D.Price</Col>
                  <Col className="fw-bold text-secondary">GST</Col>
                  <Col className="fw-bold text-secondary">Stock.Left</Col>
                  <Col className="fw-bold text-secondary">Amount</Col>
                  <Col className="fw-bold text-secondary"></Col>
                </Row>
                <Row className="mb-0">
                  <Col md={3} className=" ps-0 position-relative">
                    <Form className="mb-0 pb-0">
                      <InputGroup className="cust-det ps-0">
                        <InputGroup.Text id="basic-addon1 border-0 border-bottom phn-cust pb-0 mb-0">
                          <i className="fas fa-search"></i>
                        </InputGroup.Text>
                        <Form.Control
                          type="search"
                          placeholder="Search medicine here. (eg. 'Dol' or 'Dolo 650')"
                          className="new-cust border-0 border-bottom ps-0"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      </InputGroup>
                    </Form>
                    <div className="ms-4 bg-auto">
                      {data
                        .filter((item) => {
                          const searchItem = searchValue.toLowerCase();
                          const medicinSearch = item.medicine.toLowerCase();
                          return (
                            searchItem &&
                            medicinSearch.startsWith(searchItem) &&
                            medicinSearch !== searchItem
                          );
                        })
                        .map((item) => {
                          return (
                            <Row
                              onClick={() => {
                                onSearchMedicine(item.medicine);
                                onBillItemHandler(item.id);
                              }}
                              className="medi-item mx-0"
                            >
                              <Col md={8}>{item.medicine}</Col>
                              <Col className="text-danger fw-bold">
                                <i className="fa fa-rupee fa-xs"></i>&nbsp;
                                {item.mrp}
                              </Col>
                              <Col className="text-primary fw-bold">
                                {item.stkleft}&nbsp;
                                <small className="item-stock">(Stock)</small>
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.batch}
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.expdate}
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.mrp}
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={billItem.qty}
                      onChange={(e) =>
                        setBillItem({ ...billItem, qty: e.target.value })
                      }
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.discount}
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.dprice}
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.gst}
                      className="new-cust"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      disabled
                      value={billItem.stkleft}
                      className="new-cust"
                    />
                  </Col>
                  <Col></Col>
                  <Col className="d-flex items-center">
                    <Button
                      variant="primary"
                      size="sm"
                      className="px-3"
                      onClick={addItemtoBillHandler}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <section className="tb-sales-bill">
                  {printBillItems.length > 0
                    ? printBillItems.map((item) => {
                        return (
                          <Row className="mb-2 pb-1 border-0 border-bottom">
                            <Col md={3} className="fw-bold text-secondary">
                              {item.medicine}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.batch}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.expdate}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.mrp}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.qty}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.discount}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.dprice}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.gst}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.stkleft}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              {item.amount}
                            </Col>
                            <Col className="fw-bold text-secondary">
                              <Form.Label>
                                <i className="fas fa-trash-alt fa-md text-danger"></i>
                              </Form.Label>
                            </Col>
                          </Row>
                        );
                      })
                    : ""}
                </section>
                <section>
                  <Row className="fixed-bottom bg-danger py-1 mx-2 rounded-sm">
                    <Col md={10}>
                      <Row>
                        <Col></Col>
                        <Col>
                          <Row className="text-white d-flex items-center">
                            <Col>
                              <b>
                                {printBillItems.reduce(
                                  (a, b) => (a = a + parseInt(b.qty)),
                                  0
                                )}
                              </b>
                              &nbsp;
                              <span className="bg-text">Qty</span>
                            </Col>
                            <Col>
                              <b>{printBillItems.length}</b>&nbsp;
                              <span className="bg-text">Items</span>
                            </Col>
                            <Col
                              md={8}
                              className="d-flex justify-between items-center"
                            >
                              <span className="bg-text">Net Payable</span>
                              <span className="fs-4 fw-bold">
                                {printBillItems.reduce(
                                  (a, b) => (a = a + parseFloat(b.amount)),
                                  0
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col></Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </Col>
          <Col md={2} className="bg-custde bg-history rounded-sm border">
            <h6 className="text-dark text-center py-1 mb-0 text-decoration-underline">
              User's Order History &nbsp;
              <i className="fas fa-history cursor-pointer text-primary"></i>
            </h6>
            <p className="text-center text-secondary pt-5">
              <i className="fas fa-capsules"></i>
              <br />
              No data available!
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Sales;
