import React from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  FormLabel,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  OverlayTrigger,
  Row,
  Tab,
  Tooltip,
} from "react-bootstrap";
import { Link, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import MediLogo from "../assets/logo.png";
import DropdownMenu from "react-bootstrap/DropdownMenu";

const MainLayout = () => {
  const navigation = useNavigate();
  const user = localStorage.getItem("user");
  return (
    <>
      <Navbar expand={"lg"} className="bg-menu mb-0 py-1" sticky="top">
        <Container fluid>
          <Navbar.Brand href="#">
            <Link to={"/"}>
              <Image src={MediLogo} className="img-fluid" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {user === "Store" ? (
                ""
              ) : (
                <Nav.Link href="#action1" className="fw-bold text-white">
                  Orders
                </Nav.Link>
              )}

              <Nav.Link href="/Sales" className="fw-bold text-white">
                Sales
              </Nav.Link>
              {user === "Store" ? (
                ""
              ) : (
                <>
                  <Nav.Link href="/Purchases" className="fw-bold text-white">
                    Purchase
                  </Nav.Link>
                  <Nav.Link href="#action2" className="fw-bold text-white">
                    Inventory
                  </Nav.Link>

                  <Nav.Link href="#action2" className="fw-bold text-white">
                    Marketplace
                  </Nav.Link>

                  <NavDropdown
                    title="More"
                    className="fw-bold bg-text me-5"
                    id={`bg-text`}
                    drop="down"
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              )}
              <Nav.Link href="#action2" className="fw-bold text-white">
                Help
              </Nav.Link>
            </Nav>
            <Nav className="align-items-center">
              <Nav.Link href="#deets" className="text-white px-2">
                <i className="fas fa-search"></i>
              </Nav.Link>
              <Nav.Link eventKey={2} href="#memes" className="text-white px-2">
                <i className="fas fa-bell"></i>
              </Nav.Link>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outlined"
                  id="dropdown-basic"
                  className="text-white"
                  drop="start"
                >
                  <i className="fas fa-user-circle fa-2xl"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="user-drop">
                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Another action
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item>
                    <FormLabel
                      onClick={() => {
                        localStorage.removeItem("user");
                        navigation("/");
                      }}
                      className="cursor-pointer"
                    >
                      Log Out
                    </FormLabel>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default MainLayout;
