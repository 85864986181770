import React, { useState } from "react";
import { Button, Col, Form, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MediLogo from "../assets/medilogo.png";

const Login = () => {
  const navigation = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [validated, setValidated] = useState(false);
  const loginHandler = (e) => {
    e.preventDefault();
    setValidated(true);
    if (user.username === "Store" && user.password === "123456789") {
      navigation("/Sales");
      localStorage.setItem("user", "Store");
    } else if (user.username === "Admin" && user.password === "04023092309") {
      navigation("/Index");
      localStorage.setItem("user", "Admin");
    }
  };
  return (
    <>
      <section className="login position-relative">
        <Row className="mx-0">
          <Col className="h-100" md={8}>
            <div className="login-form">
              <p className="d-flex justify-center items-center py-2">
                <Image src={MediLogo} className="img-fluid" />
              </p>
              <Form
                className="login-medi"
                noValidate
                validated={validated}
                onSubmit={loginHandler}
              >
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text-white">Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter username"
                    className="bg-login"
                    required
                    minLength={5}
                    value={user.username}
                    onChange={(e) =>
                      setUser({ ...user, username: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter username.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="text-white">Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password"
                    className="bg-login"
                    required
                    minLength={8}
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Button
                    type="submit"
                    variant="success"
                    className="w-100 my-2 fs-6 fw-bold"
                  >
                    <i className="fas fa-sign-in-alt"></i>
                    &nbsp;&nbsp;&nbsp;Sign In{" "}
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Login;
