import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";

const NewPurchase = () => {
  const [medicine, setmedicine] = useState({
    mname: "",
    batch: "",
    expdate: "",
    mrp: "",
    ptr: "",
    qty: "",
    perunittabs: "",
    freeqty: "",
    schamt: "",
    discount: "",
    baseprice: "",
    gst: "",
    amount: "",
  });
  const medicines = [];
  const [medi, setMedi] = useState([]);
  let gstAmnt = 0;

  const addMedicineHandler = () => {
    const totalAmt = parseInt(medicine.qty) * parseFloat(medicine.baseprice);
    gstAmnt = totalAmt + (totalAmt * parseInt(medicine.gst)) / 100;
    console.log(totalAmt);
    console.log(gstAmnt);
    setMedi((current) => [
      ...medi,
      {
        ...medicine,
        amount: gstAmnt,
      },
    ]);
    console.log(medi);
    setmedicine({
      mname: "",
      batch: "",
      expdate: "",
      mrp: "",
      ptr: "",
      qty: "",
      perunittabs: "",
      freeqty: "",
      schamt: "",
      discount: "",
      baseprice: "",
      gst: "",
      amount: "",
    });
  };
  const saveMedicinesHandler = () => {
    if (medi.length > 0) {
      localStorage.setItem("medicines", JSON.stringify(medi));
      setMedi([]);
    } else {
      alert("Please add medicines");
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="py-2">
          <Col>
            <Row className="my-0">
              <Col className="my-0 py-0 d-flex justify-between">
                <h4 className="pb-1 mb-0">Add Purchase</h4>
                <Button
                  variant="primary"
                  size="md"
                  className="px-4 py-1"
                  onClick={saveMedicinesHandler}
                >
                  Save&nbsp;&nbsp;&nbsp;<i className="fas fa-save"></i>
                </Button>
              </Col>
            </Row>
            <h6 className="text-secondary mb-0">Distributor Details</h6>
            <Table responsive>
              <thead>
                <tr>
                  <td className="fw-bold text-secondary">Distributor Name</td>
                  <td className="fw-bold text-secondary">Distributor Phone</td>
                  <td className="fw-bold text-secondary">GSTIN</td>
                  <td className="fw-bold text-secondary">Invoice No.</td>
                  <td className="fw-bold text-secondary">Purchase Date</td>
                  <td className="fw-bold text-secondary">Payment Mode</td>
                  <td className="fw-bold text-secondary">Due Date</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Control type="text" />
                  </td>
                  <td>
                    <Form.Control type="text" />
                  </td>
                  <td>
                    <Form.Control type="text" />
                  </td>
                  <td>
                    <Form.Control type="text" />
                  </td>
                  <td>
                    <Form.Control type="date" />
                  </td>
                  <td>
                    <Form.Select>
                      <option value="Cash">Cash</option>
                      <option value="Credit">Credit</option>
                      <option value="UPI">UPI</option>
                      <option value="Cheque">Cheque</option>
                      <option value="CC/DC">CC/DC</option>
                      <option value="RTGS/NEFT">RTGS/NEFT</option>
                    </Form.Select>
                  </td>
                  <td>
                    <Form.Control type="date" />
                  </td>
                </tr>
              </tbody>
            </Table>
            <h6 className="text-secondary mb-0 fw-bold mt-0">
              Product Details
            </h6>
            <Table responsive>
              <thead className="sticky">
                <tr>
                  <td className="fw-bold text-secondary">Medicine Name</td>
                  <td className="fw-bold text-secondary">Batch</td>
                  <td className="fw-bold text-secondary">Exp. Date</td>
                  <td className="fw-bold text-secondary">MRP</td>
                  <td className="fw-bold text-secondary">PTR</td>
                  <td className="fw-bold text-secondary">QTY</td>
                  <td className="fw-bold text-secondary">Tabs/unit</td>
                  <td className="fw-bold text-secondary">Free</td>
                  <td className="fw-bold text-secondary">Sch. Amt</td>
                  <td className="fw-bold text-secondary">Disc%</td>
                  <td className="fw-bold text-secondary">Base</td>
                  <td className="fw-bold text-secondary">GST</td>
                  <td className="fw-bold text-secondary">Amount</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="medi-td">
                    <Form.Control
                      type="text"
                      value={medicine.mname}
                      onChange={(e) =>
                        setmedicine({ ...medicine, mname: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      className=""
                      value={medicine.batch}
                      onChange={(e) =>
                        setmedicine({ ...medicine, batch: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="month"
                      className=""
                      value={medicine.expdate}
                      onChange={(e) =>
                        setmedicine({ ...medicine, expdate: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.mrp}
                      onChange={(e) =>
                        setmedicine({ ...medicine, mrp: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.ptr}
                      onChange={(e) =>
                        setmedicine({ ...medicine, ptr: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.qty}
                      onChange={(e) =>
                        setmedicine({ ...medicine, qty: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.perunittabs}
                      onChange={(e) =>
                        setmedicine({
                          ...medicine,
                          perunittabs: e.target.value,
                        })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.freeqty}
                      onChange={(e) =>
                        setmedicine({ ...medicine, freeqty: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.schamt}
                      onChange={(e) =>
                        setmedicine({ ...medicine, schamt: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.discount}
                      onChange={(e) =>
                        setmedicine({ ...medicine, discount: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.baseprice}
                      onChange={(e) =>
                        setmedicine({ ...medicine, baseprice: e.target.value })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={medicine.gst}
                      onChange={(e) => {
                        setmedicine({
                          ...medicine,
                          gst: e.target.value,
                        });
                      }}
                    />
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={13} className="text-end">
                    <Button
                      variant="primary"
                      size="md"
                      className="py-1 px-4"
                      onClick={addMedicineHandler}
                    >
                      <i className="far fa-check-circle"></i> &nbsp;&nbsp;Add
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <section className="tb-purchase">
              {medi.length > 0 ? (
                medi.map((medic) => {
                  return (
                    <Row className="border-bottom py-2 px-2 mx-0">
                      <Col md={3}>{medic.mname}</Col>
                      <Col>{medic.batch}</Col>
                      <Col md={2}>{medic.expdate}</Col>
                      <Col>{medic.mrp}</Col>
                      <Col>{medic.ptr}</Col>
                      <Col>{medic.qty}</Col>
                      <Col>{medic.perunittabs}</Col>
                      <Col>{medic.freeqty}</Col>
                      <Col>{medic.schamt}</Col>
                      <Col>{medic.discount}</Col>
                      <Col className="text-start">{medic.baseprice}</Col>
                      <Col className="text-center">{medic.gst}</Col>
                      <Col>{medic.amount}</Col>
                      <Col>
                        <i className="fas fa-trash-alt text-danger cursor-pointer"></i>
                        &nbsp;&nbsp;&nbsp;
                        <i className="fas fa-eye text-primary cursor-pointer"></i>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <h1 className="text-center text-secondary">
                  <i className="fas fa-capsules"></i>
                  <br />
                  No medicines
                </h1>
              )}
            </section>
          </Col>
        </Row>
        <section>
          <Row className="fixed-bottom bg-danger py-1 mx-2 rounded-sm">
            <Col></Col>
            <Col>
              <Row className="text-white d-flex items-center">
                <Col>
                  <b>
                    {medi.reduce(
                      (a, b) => (a = a + parseInt(b.qty) + parseInt(b.freeqty)),
                      0
                    )}
                  </b>
                  &nbsp;
                  <span className="bg-text">Qty</span>
                </Col>
                <Col>
                  <b>{medi.length}</b>&nbsp;
                  <span className="bg-text">Items</span>
                </Col>
                <Col>
                  <b>
                    {medi.reduce(
                      (a, b) =>
                        (a =
                          a +
                          (parseInt(b.qty) *
                            parseInt(b.baseprice) *
                            parseInt(b.gst)) /
                            100),
                      0
                    )}
                  </b>
                  &nbsp;
                  <span className="bg-text">GST</span>
                </Col>
                <Col md={4} className="d-flex justify-between items-center">
                  <span className="bg-text">Net</span>
                  <span className="fs-4 fw-bold">
                    {medi
                      .reduce((a, b) => (a = a + parseFloat(b.amount)), 0)
                      .toFixed(2)}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
    </>
  );
};

export default NewPurchase;
